// react and next imports
import React, { useCallback, useEffect, useState } from "react";
import { Image as DatoImage } from "react-datocms";
import Link from "next/link";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

// imports comopoents
const Logo = dynamic(() => import("@/common/UI/Logo"));
const Divider = dynamic(() => import("@/components/Divider"));

// imports utilities
import { getSelectedLangData, isEmpty } from "@/utils/functions";
import DividerImage2 from "@/assets/Divider2.png";
import StructuredText from "@/common/UI/StructuredText";

function Footer({ siteConfig, labelsList }) {
  const router = useRouter();
  const { isPreview, locale, pathname } = router;

  const [showData, setShowData] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowData(true);
    }, 200);
  }, [siteConfig]);

  const numberFromText = useCallback((textNumber) => {
    const number = textNumber?.split("+")?.pop();
    if (!isEmpty(number !== "")) {
      return `+${number}`;
    }
  }, []);

  return (
    <>
      <Divider imgUrl={DividerImage2} />
      <footer className="pt-10 xxs:pb-20 flex flex-col items-center">
        <div className="container flex justify-between text-center text-primary lg:flex-row xxs:flex-col gap-7">
          <div className="lg:max-w-[444px] flex flex-col items-center justify-center gap-8">
            {siteConfig && (
              <div>
                <Logo siteConfig={siteConfig} regular={true} />
              </div>
            )}
            <div className="font-nunito font-normal tracking-[0.17px]">
              <p className="mb-4">{siteConfig?.copyright}</p>
              <p
                className="cursor-pointer font-bold Bold font-futuraBold"
                onClick={() => setShowModal(true)}
              >
                {siteConfig?.credit}
              </p>
            </div>
          </div>
          <div className="lg:max-w-[444px]">
            <p className="font-normal">{labelsList?.address}</p>
            {siteConfig?.fax?.length && (
              <p className="font-bold flex justify-center items-center flex-wrap">
                <Link
                  href={`tel:${numberFromText(siteConfig?.fax?.split("|")[0])}`}
                  className="hover:text-[#5b9bd6] font-futuraBold"
                >
                  {siteConfig?.fax?.split("|")[0]}
                </Link>
                <span className="text-base mx-[2px] font-futuraBold">|</span>
                <Link
                  href={`tel:${numberFromText(siteConfig?.fax?.split("|").pop())}`}
                  className="hover:text-[#5b9bd6] font-futuraBold"
                >
                  {siteConfig?.fax?.split("|").pop()}
                </Link>
              </p>
            )}
            {labelsList?.email && (
              <Link href={`mailto:${labelsList?.email}`} className="hover:text-[#5b9bd6]">
                <p className="my-[38px] font-bold inline-block font-futuraBold">
                  {labelsList?.email}
                </p>
              </Link>
            )}
            <div className="flex justify-center items-center gap-4">
              <div className="relative w-[30px] h-[30px]">
                <DatoImage
                  data={{
                    src: siteConfig?.dogsNotAllowedIcon?.url,
                    height: 30,
                    width: 30,
                    alt: siteConfig?.dogsNotAllowedIcon?.alt || "dogs not allowed",
                    titleL: siteConfig?.dogsNotAllowedIcon?.title || "dogs not allowed",
                    placeholder: !isEmpty(siteConfig?.dogsNotAllowedIcon?.blurUpThumb)
                      ? "blur"
                      : "empty",
                    blurDataURL: siteConfig?.dogsNotAllowedIcon?.blurUpThumb,
                  }}
                  pictureClassName="object-cover"
                />
              </div>
              <h5 className="font-futuraBold">{siteConfig?.dogsNotAllowed}</h5>
            </div>
          </div>
          <div className="lg:max-w-[444px]">
            <div className="flex items-center justify-center">
              {showData && (
                <>
                  {siteConfig?.facebookLink && (
                    <Link href={siteConfig?.facebookLink} target="_blank">
                      <DatoImage
                        data={{
                          src: siteConfig?.facebookIcon?.url,
                          height: 24,
                          width: 24,
                          alt: "Facebook",
                          placeholder: !isEmpty(siteConfig?.facebookIcon?.blurUpThumb)
                            ? "blur"
                            : "empty",
                          blurDataURL: siteConfig?.facebookIcon?.blurUpThumb,
                        }}
                        pictureClassName="object-cover"
                      />
                    </Link>
                  )}
                  {siteConfig?.instagramLink && (
                    <Link href={siteConfig?.instagramLink} className="mx-4" target="_blank">
                      <DatoImage
                        data={{
                          src: siteConfig?.instagramIcon?.url,
                          height: 24,
                          width: 24,
                          alt: "Instagram",
                          placeholder: !isEmpty(siteConfig?.instagramIcon?.blurUpThumb)
                            ? "blur"
                            : "empty",
                          blurDataURL: siteConfig?.instagramIcon?.blurUpThumb,
                        }}
                        pictureClassName="object-cover"
                      />
                    </Link>
                  )}
                  {siteConfig?.youtubeLink && (
                    <Link href={siteConfig?.youtubeLink} target="_blank">
                      <DatoImage
                        data={{
                          src: siteConfig?.youtubeIcon?.url,
                          height: 24,
                          width: 24,
                          alt: "Youtube",
                          placeholder: !isEmpty(siteConfig?.youtubeIcon?.blurUpThumb)
                            ? "blur"
                            : "empty",
                          blurDataURL: siteConfig?.youtubeIcon?.blurUpThumb,
                        }}
                        pictureClassName="object-cover"
                      />
                    </Link>
                  )}
                </>
              )}
            </div>
            {showData && (
              <div className="mt-[25px] flex flex-col justify-center items-center">
                <a
                  href={`/${locale !== "en" ? `${locale}/` : ""}privacy-policy`}
                  className=" font-bold hover:text-[#5b9bd6] text-xs font-futuraBold mb-2"
                >
                  {siteConfig?.privacyPolicy}
                </a>

                {/* <span className="font-bold text-base mx-2 ">|</span> */}

                <p
                  id="csconsentlink"
                  className=" font-bold hover:text-[#5b9bd6] text-xs font-futuraBold cursor-pointer"
                >
                  {siteConfig?.cookiePolicy}
                </p>
              </div>
            )}
          </div>
        </div>
        {pathname !== "/bungalows" && (
          <div className="xxs:block lg:hidden w-full fixed bottom-0 z-10">
            <Link
              href={`${siteConfig?.bookingEngine?.[0]?.baseUrl}&lang=${getSelectedLangData()}`}
              target="_blank"
              className="bg-success max-h-[52px] text-white  flex items-center justify-center gap-4 py-4 font-bold uppercase w-full"
            >
              <h4 className="text-xs font-futuraBold">{labelsList?.bookNow}</h4>
              <div className="text-white flex justify-centers items-center">
                <iconify-icon
                  icon="material-symbols:calendar-month-outline"
                  width="20"
                  height="20"
                ></iconify-icon>
              </div>
            </Link>
          </div>
        )}
      </footer>
      {isPreview && (
        <section
          className={`bg-[#1ba904a3] z-50 fixed font-Inter
          ${
            isPreviewOpen
              ? "py-[38px] md:py-4 px-6 bottom-0 w-full"
              : "w-[62px] right-[10px] rounded-lg bottom-[10px]"
          }`}
        >
          {isPreviewOpen && (
            <div
              className="absolute text-white top-3 right-3 cursor-pointer"
              onClick={() => setIsPreviewOpen(false)}
            >
              <iconify-icon
                className="iconify"
                icon="ep:close-bold"
                width="24"
                height="44"
              ></iconify-icon>
            </div>
          )}
          <div className="flex flex-col items-center justify-center gap-4 text-white md:flex-row">
            <div
              className={`flex items-center justify-center
              ${isPreviewOpen ? "" : "py-[13px] px-4"}
              `}
              onClick={() => setIsPreviewOpen(true)}
            >
              <iconify-icon className="iconify" icon="mdi:eye" width="30"></iconify-icon>
            </div>
            {isPreviewOpen && (
              <div className="flex flex-col gap-6 md:flex-row items-center">
                <span className="text-sm font-bold leading-4 text-center md:text-left">
                  This website is currently viewed in preview mode and may not reflect the content
                  of the latest build
                </span>
                <Link
                  href="/api/exit-preview/"
                  className="btn rounded-[20px] py-[7px] px-6 font-bold text-sm leading-[17px] tracking-widest border w-max text-white hover:bg-[#ffffff1a]"
                >
                  EXIT PREVIEW MODE
                </Link>
              </div>
            )}
          </div>
        </section>
      )}
      {showModal && (
        <>
          <div className="modal">
            <div className="text-[#333333] bg-white modal-content m-auto lg:w-[40%] sm:w-[80%] xxs:w-[90%] max-h-[80%] relative flex flex-col rounded-lg">
              <div className="flex flex-col gap-4 py-5 overflow-auto">
                <div
                  onClick={() => setShowModal(false)}
                  className="close absolute -top-[10px] -right-[10px] text-[#f1f1f1] text-xl font-bold bg-danger"
                >
                  <iconify-icon icon="system-uicons:cross" width="25" height="25"></iconify-icon>
                </div>
                <h5 className="text-left text-xl leading-7 tracking-[0.2px] font-bold font-futuraBold px-5">
                  {siteConfig?.credit}
                </h5>
                <hr />
                <div className="text-base px-5">
                  {typeof siteConfig?.credits === "string" ? (
                    <p className="">{abstract}</p>
                  ) : (
                    <div className="structure-format">
                      <StructuredText data={siteConfig?.credits} />
                    </div>
                  )}
                </div>
                <hr />
                <div className="flex flex-row-reverse w-full px-5">
                  <button
                    className="bg-light-blue text-white font-bold font-futuraBold px-3 py-2 rounded-sm"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Footer;
