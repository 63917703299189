import { StructuredText as DatoStructuredText, renderNodeRule } from "react-datocms";
import { isLink } from "datocms-structured-text-utils";
import Link from "next/link";

export default function StructuredText({ data }) {
  const parsedBlock = data?.schema
    ? {
        schema: data.schema,
        document: {
          type: data.document.type,
          children: data.document.children.map((item) => {
            if (item.type === "paragraph") {
              // if there are children that have empty span then convert them to line break
              const paraChildren = item.children.map((childItem) => {
                if (!childItem.value && childItem.type === "span") {
                  return {
                    type: "span",
                    value: "\r\n",
                  };
                } else return childItem;
              });

              return {
                type: item.type,
                children: paraChildren,
              };
            }

            return item;
          }),
        },
      }
    : data;

  return (
    <div className="whitespace-break-spaces structured-text">
      <DatoStructuredText
        data={parsedBlock}
        customNodeRules={[
          renderNodeRule(isLink, ({ node }) => {
            const linkText = node?.children?.[0]?.value;
            const linkUrl = node?.url;

            return (
              <Link href={linkUrl} className="text-danger" target="_blank">
                {linkText}
              </Link>
            );
          }),
        ]}
      />
    </div>
  );
}
